<template>
	<div class="c-app flex-row align-items-center">
		<CContainer>
			<vue-loading :active.sync="isLoading" :is-full-page="false"/>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<CCard class="p-4">
							<CCardBody>
								<CForm @submit.prevent="onSubmit">
									<h1>Prijava</h1>
									<p class="text-muted">Prijava v aplikacijo z uporabniškim računom</p>
									<CInput
										type="text"
										required
										v-model="username"
										:disabled="isLoading"
										:isValid="isValidInput"
										placeholder="Uporabniško ime"
										autocomplete="username email">
										<template #prepend-content><CIcon name="cil-user"/></template>
									</CInput>
									<CInput
										type="password"
										required
										v-model="password"
										:disabled="isLoading"
										:isValid="isValidInput"
										placeholder="Geslo"
										autocomplete="curent-password">
										<template #prepend-content><CIcon name="cil-lock-locked"/></template>
									</CInput>
									<CRow>
										<CCol col="6" class="text-left">
											<CButton type="submit" color="primary" class="px-4" :disabled="isLoading">Prijava</CButton>
										</CCol>
										<CCol col="6" class="text-right">
											<CButton color="link" class="px-0">Ste pozabili geslo?</CButton>
											<CButton color="link" class="d-lg-none">Pomoč</CButton>
										</CCol>
									</CRow>
									<app-mbox v-if="showErrorMessage" :text="error_message" @dismissed="onDismissed"></app-mbox>
								</CForm>
							</CCardBody>
						</CCard>
						<CCard
							color="primary"
							text-color="white"
							class="text-center py-5 d-md-down-none"
							body-wrapper>
							<CCardBody>
								<h2>Težave s prijavo?</h2>
								<p>V sistem se lahko prijavite samo z že obstoječim uporabniškim imenom in geslom. Če imate pri prijavi težave, nas lahko preko spodnjega obrazca kontaktirate.</p>
								<CButton
									color="light"
									variant="outline"
									size="lg">
									Pomoč
								</CButton>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import { AUTH_LOGIN } from "@/store/actions/auth";

export default {
	name: 'Login',
	data() {
		return {
			username: '',
			password: '',
			error_message: null,
			isLoading: false
		};
	},
	methods: {
		onSubmit: function() {
			this.isLoading = true;
			this.error_message = null;
			const { username, password } = this;
			this.$store.dispatch(AUTH_LOGIN, { username, password })
				.then(() => {
					this.isLoading = false;
					this.$router.push("/");
				})
				.catch(error => {
					this.isLoading = false;
					if(error.response && error.response.data && error.response.data.message) {
						this.error_message = error.response.data.message + (error.response.data.code ? ' (' + error.response.data.code + ')' : '');
					}
					else {
						this.error_message = 'Prijava ni uspela! Prosimo poizkusite kasneje.';
					}
				});
		},
		onDismissed: function() {
			this.error_message = null;
		}
	},
	computed: {
		showErrorMessage() {
			return (this.error_message != null && this.error_message != '')
		},
		isValidInput() {
			return (this.error_message != null && this.error_message != '') ? false : null;
		}
	},
}
</script>
